import { MdLocationPin,MdCall,MdMail } from "react-icons/md";
import {
  BsFacebook,
  BsMedium,
  BsLinkedin
} from "react-icons/bs";

export default function Footer() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  return (
    <>
      <div className="bg-defaultBg border-t shadow-md dark:bg-[rgba(0,0,0)] dark:border-teal-400">
        <div className="container">
          <div className=" grid grid-cols-3 p-4 lg:px-4 gap-[100px] max-md:gap-[10px] max-md:grid-cols-2 ">
          <div className="max-md:col-span-2 ">
            <a href={'/#'}>
            <img
              src="/images/LogoLong.png"
              alt="logo long"
              className=" max-w-[170px] max-md:h-8 max-md:w-[110px]"
            />
            </a>
            <div  className="my-10 grid grid-cols-5 w-[250px] max-md:w-[150px] max-md:my-[20px]">
            <div>
            <a href="https://www.facebook.com/BPSolution.io">
              <div className="flex items-center gap-2">
                <BsFacebook className="text-3xl max-md:text-[16px] text-[#3b5998]" />
              </div>
            </a>
            </div>
            <div >
            <a href="https://bpsolution.medium.com/">
              <div className="flex items-center gap-2">
                <BsMedium className=" text-3xl max-md:text-[16px] text-[#000] dark:text-[#fff]" />
              </div>
            </a>
            </div>
            <div >
            <a href="https://www.linkedin.com/company/bpsolution">
              <div className="flex items-center gap-2">
                <BsLinkedin className=" text-3xl max-md:text-[16px] text-[#0e76a8]" />
              </div>
            </a>
            </div>
          </div>
          </div>
          <div className=" dark:text-[rgba(255,255,255,.9)] max-md:text-[10px]">
            <h2 className="font-semibold text-xl pb-3 max-md:text-[16px]">Холбоос</h2>
            <a href={'/aboutUs'} className="font-semibold">Бидний тухай</a>
            <div className="font-semibold">Бүтээгдэхүүн, үйлчилгээ</div>
            <ul className="pl-4 text-sm max-md:text-[10px]"> 
                <li><a href={'/bpm'}>Бизнес процесс менежмент</a></li>
                <li><a href={'/bpms'}>Процесс удирдлагын систем</a></li>
                <li><a href={'/rpa'}>Роботик процесс автоматжуулалт</a></li>
                <li><a href={'/pds'}>Процессын загварчлалын систем</a></li>
            </ul>
            <a href={'/contactUs'} className="font-semibold">Холбоо барих</a>
          </div>
          <div className=" dark:text-[rgba(255,255,255,.9)] max-md:text-[10px]">
          <h2 className="font-semibold text-xl pb-3 max-md:text-[16px]">Холбоо барих</h2>
          <a href="mailto:contact@bpsolution.io" className="flex gap-2"><div><MdMail className="text-2xl text-teal-400 max-md:text-lg"/></div> <div>contact@bpsolution.io</div></a>
          <a href="tel:+97699037128" className="flex gap-2 my-2"><div><MdCall className="text-2xl text-teal-400 max-md:text-lg"/></div><div>(+976) 8810 1598</div></a>
          <div className="flex gap-2"><div><MdLocationPin className="text-2xl text-teal-400 max-md:text-lg"/> </div><div>Улаанбаатар хот, Сүхбаатар дүүрэг, Twin Tower 1, 15 давхар</div></div>
          </div>
          </div>
          <p className=" max-md:font-normal max-md:text-[10px] dark:text-[#fff] text-center border-t py-4 mt-5">
              Бүх эрх хуулиар хамгаалагдсан. {year} он
        </p>
         
          
        </div>
      </div>
    </>
  );
}
